

import Velocity from 'velocity-animate'

export default {

  name: "app-home-first-team-page",
  data() {
    return {
      ASSET_URL: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/215059/',
      $stage: null,
      $world: null,
      $terrain: null,
      $team: null,
      $teamListHome: null,
      $players: null,
      $playersHome: null, // Subset of $players
      $playersAway: null, // Subset of $players
      $switchBtn: null,
      $loadBtn: null,
      $closeBtn: null,
      $heading: null,
      $subHeading: null,
      $loading: null,
      $switcher: null,



      data: {
        players: {

          home: [
            { name: 'Pizarro', asset: 'bm-pizarro.jpg', origin: 'Peru', height: '1.84m', shirt: '14', pos: 'Forward', dob: '36', goals: 1, games: 16, x: 110, y: -190 },
            { name: 'Robben', asset: 'bm-robben.png', origin: 'Holland', height: '1.80m', shirt: '10', pos: 'Forward', dob: '32', goals: 19, games: 30, x: -110, y: -190 },
            { name: 'Rilbery', asset: 'bm-rilbery.jpg', origin: 'France', height: '1.70m', shirt: '7', pos: 'Midfield', dob: '32', goals: 9, games: 22, x: 150, y: 50 },
            { name: 'Schweinsteiger', asset: 'bm-schweinsteiger.jpg', origin: 'Germany', height: '1.87m', shirt: '24', pos: 'Forward', dob: '31', goals: 21, games: 3, x: 0, y: 100 },
            { name: 'Martinez', asset: 'bm-martinez.jpg', origin: 'Spain', height: '1.90m', shirt: '8', pos: 'Midfield', dob: '28', goals: 0, games: 2, x: -150, y: 50 },
            { name: 'Alaba', asset: 'bm-alaba.jpg', origin: 'Austria', height: '1.80m', shirt: '27', pos: 'Defence', dob: '24', goals: 5, games: 27, x: -200, y: 180 },
            { name: 'Lahm', asset: 'bm-lahm.jpg', origin: 'Germany', height: '1.70', shirt: '21', pos: 'Defence', dob: '32', goals: 2, games: 25, x: 200, y: 180 },
            { name: 'Benatia', asset: 'bm-benatia.jpg', origin: 'France', height: '1.87m', shirt: '5', pos: 'Defence', dob: '31', goals: 21, games: 1, x: 100, y: 300 },
            { name: 'Dante', asset: 'bm-dante.jpg', origin: 'Brazil', height: '1.87m', shirt: '4', pos: 'Defence', dob: '32', goals: 0, games: 34, x: -100, y: 300 },
            { name: 'Neuer', asset: 'bm-neuer.jpg', origin: 'Germany', height: '1.93m', shirt: '1', pos: 'Goalie', dob: '29', goals: 0, games: 48, x: 0, y: 410 },
          ],
          away: [
            { name: 'Benzema', asset: 'rm-benzema.jpg', origin: 'France', height: '1.87m', shirt: '9', pos: 'Forward', dob: '36', goals: 1, games: 16, x: 110, y: -190 },
            { name: 'Bale', asset: 'rm-bale.jpg', origin: 'Wales', height: '1.83m', shirt: '11', pos: 'Midfield', dob: '26', goals: 19, games: 30, x: -110, y: -190 },
            { name: 'carvajal', asset: 'rm-carvajal.jpg', origin: 'Spain', height: '1.70m', shirt: '15', pos: 'Defender', dob: '32', goals: 9, games: 22, x: 150, y: 50 },
            { name: 'Silva', asset: 'rm-silva.jpg', origin: 'Brazil', height: '1.87m', shirt: '16', pos: 'Forward', dob: '22', goals: 21, games: 3, x: 0, y: 100 },
            { name: 'Kroos', asset: 'rm-kroos.jpg', origin: 'Germany', height: '1.82', shirt: '8', pos: 'Midfield', dob: '25', goals: 0, games: 2, x: -150, y: 50 },
            { name: 'Modric', asset: 'rm-modric.jpg', origin: 'Croatia', height: '1.74m', shirt: '19', pos: 'Midfield', dob: '30', goals: 5, games: 27, x: -200, y: 180 },
            { name: 'Nacho', asset: 'rm-nacho.jpg', origin: 'Germany', height: '1.79', shirt: '18', pos: 'Defence', dob: '25', goals: 2, games: 25, x: 200, y: 180 },
            { name: 'Ramos', asset: 'rm-ramos.jpg', origin: 'Spain', height: '1.83m', shirt: '4', pos: 'Defence', dob: '31', goals: 21, games: 1, x: 100, y: 300 },
            { name: 'Pepe', asset: 'rm-pepe.jpg', origin: 'Brazil', height: '1.88m', shirt: '3', pos: 'Defence', dob: '32', goals: 0, games: 34, x: -100, y: 300 },
            { name: 'Casillas', asset: 'rm-casillas.jpg', origin: 'Spain', height: '1.85m', shirt: '1', pos: 'Goalie', dob: '34', goals: 0, games: 48, x: 0, y: 410 },
          ]
        }
      },
      pos: {
        world: {
          baseX: 0,
          baseY: 0,
          baseZ: -200
        },
        def: {
          goalie: [0, -50]
        }
      }
    }
  },
  methods: {
    addPlayers(side) {
      for (let val in this.data.players[side]) {
        let newVal = this.data.players[side][val]
        newVal['side'] = side
        var $el = this.addPlayer(newVal)
        this.$team.innerHTML += $el
      }

      // getElementsByClassName('js-world')[0]
      this.$players = document.getElementsByClassName('js-player')
      // document.querySelectorAll("[data-foo='1']")
      this.$playersHome = document.querySelectorAll('[data-side="home"]')
      // console.log('$playersHome in addPlayers',this.$playersHome);
      // this.$playersAway = document.querySelectorAll('[data-side="away"]')
      // console.log('$playersAway in addPlayers',this.$playersAway);

    },


    //     <div class="player__card"
    //     style=""
    //     > 
    //  </div>



    //     <div class="player__card" style="display: none; transform: translateX(0px) translateY(0px); opacity: 0;"> 
    // <h3>Rilbery</h3>
    // <ul class="player__card__list">
    // <li><span>DOB</span><br>32 yr</li>
    // <li><span>Height</span><br>1.70m</li>
    // <li><span>Origin</span><br>France</li>
    // </ul>
    // <ul class="player__card__list player__card__list--last">
    // <li><span>Games</span><br>22</li>
    // <li><span>Goals</span><br>9</li>
    // </ul>
    // </div>
    addPlayer: function (data) {
      var $el;
      $el = `<div class="js-player player" data-name="` + data.name + `" data-side="` + data.side + `" data-x="` + data.x + `" data-y="` + data.y + `"
      
        >
        <div class="player__placeholder"
        
        
        >
        </div>
       


    <div class="player__card"> 
      <h3>` + data.name + `</h3>
      <ul class="player__card__list"
      ><li><span>DOB</span><br/>`+ data.dob + `yr</li><li><span>Height</span><br/> ` + data.height + ` 
      </li><li><span>Origin</span><br/>`+ data.origin + `</li></ul> +
      <ul class="player__card__list player__card__list--last"><li>
      <span>Games</span><br/>`+ data.games + ` </li><li><span>Goals</span><br/> ` + data.goals + `</li>
      </ul>
    </div>


        <div class="player__label" 
              style="
        position: absolute!important;
        height: 88px!important;
        width: 65px!important;
        padding-bottom: 23px!important;
        z-index: 9!important;
        left: 50%!important;
        margin-left: -32.5px!important;
        top: 80%!important;
        transform-style: preserve-3d!important;
        backface-visibility: hidden!important;
        transition: all 0.2s!important;
        cursor: pointer!important;
        "
        data-name="` + data.name + `"
        ><span 
        
        style="
          background-color: rgba(16,20,30,0.9)!important;
  color: #fff!important;
  font-size: 11px!important;
  padding: 3px 10px 2px 10px!important;
  border-radius: 10px!important;
  text-transform: upppercase!important;"
        >` + data.name + `</span></div>
        <div class="player__img"
        style="
        position: absolute!important;
        top: 0!important;
        left: 0!important;
        width: 65px!important;
        height: 65px!important;
        z-index: 4!important;
        overflow: hidden!important;
        border-radius: 32.5px!important;
        background-color: #000!important;
        border: solid 1px #fff!important;
        backface-visibility: hidden!important;
        transition: all 0.2s!important;"
        ><img 
        data-name="` + data.name + `"
         style="width: 100%;
  transition: all 0.2s;
  display: block;"
         src= ` + this.ASSET_URL + data.asset + `></div>
        </div>`;
      // var doc = new DOMParser().parseFromString($el, "text/xml")
      // var $playerCard = doc.getElementsByClassName('player__card')[0]
      // console.log('$playerCard',$playerCard);
      // this.populateCard($playerCard, data);
      // console.log('player card',$playerCard);
      // console.log('player',$el);
      return $el;
    },

    populateCard($el, data) {
      //  append 

      $el.innerHTML = `<h3
          style="
          text-align: center!important;
  font-weight: normal!important;
  text-transform: uppercase!important;
  font-family: montserrat!important;
  font-size: 19px!important;
  line-height: 27px!important;
  color: #333!important;
  color: #fff!important;
  padding: 15px 30px 40px!important;
  margin: 0 0 30px!important;
  background-color: #eee!important;
  border-radius: 4px 4px 0 0!important;
  background-color: #192642!important;
          "
          
          
          >` + data.name + `</h3>
            <ul class="player__card__list"
            style="
            padding: 10px 0!important;
  font-size: 14px!important;
  color: #777!important;
  overflow: auto!important;
  text-align: center!important;
            "
            
            ><li><span>DOB</span><br/>`+ data.dob + `yr</li><li><span>Height</span><br/> ` + data.height + ` </li><li><span>Origin</span><br/>` + data.origin + `</li></ul> +
            <ul class="player__card__list player__card__list--last"><li><span>Games</span><br/>`+ data.games + ` </li><li><span>Goals</span><br/> ` + data.goals + `</li></ul>`

      //  $el = $el +  '<h3>' + data.name + '</h3>' +
      //     '<ul class="player__card__list"><li><span>DOB</span><br/>' + data.dob + ' yr</li><li><span>Height</span><br/>' + data.height + '</li><li><span>Origin</span><br/>' + data.origin + '</li></ul>' +
      //     '<ul class="player__card__list player__card__list--last"><li><span>Games</span><br/>' + data.games + '</li><li><span>Goals</span><br/>' + data.goals + '</li></ul>' 
    },



    arrangePlayers() {
      console.log('this.$players', this.$players);
      this.$players.forEach(($player) => {
        Velocity($player, {
          translateX: $player.dataset.x,
          translateZ: $player.dataset.y
        })
      })

    },
    // 
    showPlayerCard($el, dur, delay) {
      console.log('show player card');
      console.log('dur', dur);
      console.log('delay', delay);
      // var doc = new DOMParser().parseFromString($el, "text/xml")
      // var $playerCard = doc.getElementsByClassName('player__card')[0]
      console.log('$playerCard', $el.getElementsByClassName('player__card')[0]);
      console.log('$playerCard', $el.getElementsByClassName('player__img')[0]);
      const $card = $el.getElementsByClassName('player__card')[0]
      const $image = $el.getElementsByClassName('player__img')[0]
      const $playerLabel = $el.getElementsByClassName('player__label')[0]
      console.log('player label', $playerLabel);
      Velocity($image, {
        translateY: '-=150px',
      }, 300)

      this.fadeOutDir($playerLabel, 200, delay)
      this.fadeInDir($card, 300, 200, 0, 100)
    },

    focusPlayer($el) {
      var shiftY;
      var data = $el.dataset;
      shiftY = data.y;
      if (shiftY > 0) {
        shiftY = data.y / 2;
      }
      console.log('focus player', shiftY);
      var allPlayers = document.getElementsByClassName('js-player')
      console.log('all players', allPlayers[0].classList);
      allPlayers.forEach((player) => {
        if (!player.classList.contains('active')) {
          console.log('not active', player.dataset);
          // var $unfocus;
          // $unfocus = player ;
          return this.fadeOutDir(player, 300, 0, player.dataset.x, player.dataset.y, 0, null, 0.2);
        }
      })
      Velocity(this.$world, {
        translateX: this.pos.world.baseX - data.x,
        translateY: this.pos.world.baseY,
        translateZ: this.pos.world.baseZ - shiftY,
      }, 600)
      Velocity(this.$terrain, {
        opacity: 0.66
      }, 600)
      return this.showPlayerCard($el, 600, 600);
    },
    fadeInDir($el, dur, delay, deltaX = 0, deltaY = 0, deltaZ = 0, easing = null, opacity = 0) {
      console.log('opacity', opacity);
      $el.style.setProperty("display", "block", "important")
      Velocity($el, {
        translateX: '-=' + deltaX,
        translateY: '-=' + deltaY,
        translateZ: '-=' + deltaZ,

      }, {
        easing: easing,
        delay: delay,
        duration: dur
      }

      )
      // Velocity($el,{
      //    opacity: 1,
      //     translateX: '+=' + deltaX,
      //     translateY: '+=' + deltaY,
      //     translateZ: '+=' + deltaZ,
      //   }, {
      //   easing: easing,
      //     delay: delay,
      //     duration: dur
      //     })

    },
    // the working function
    //   fadeOutDir($el, dur, delay, deltaX = 0, deltaY = 0, deltaZ = 0, easing = null, opacity = 0){
    //       var display = ""
    //       if (!opacity)
    //           display = 'none'
    //       else
    //           display = 'block'
    //       Velocity($el,{
    //         opacity: opacity,
    //           translateX: '+=' + deltaX,
    //           translateY: '+=' + deltaY,
    //           translateZ: '+=' + deltaZ,
    //       },{
    //         easing: easing,
    //           delay: delay,
    //           duration: dur,
    //       })
    //       Velocity($el,{
    //         opacity: opacity,
    //           translateX: '-=' + deltaX,
    //           translateY: '-=' + deltaY,
    //           translateZ: '-=' + deltaZ,
    //       },{
    //          duration: 0,
    //           display: display,
    //       })


    // },

    fadeOutDir($el, dur, delay, deltaX = 0, deltaY = 0, deltaZ = 0, easing = null, opacity = 0) {
      var display = ""
      if (!opacity)
        display = 'none'
      else
        display = 'block'

      // Velocity($el,{
      //   opacity: opacity,
      //   translateX:  deltaX,
      //   translateY:  deltaY,
      //   translateZ:  deltaZ,
      //   easing: easing,
      //   delay: delay,
      //   duration: dur,
      //   display: display
      // })


      Velocity($el, {
        opacity: opacity,
        translateX: '+=' + deltaX,
        translateY: '+=' + deltaY,
        translateZ: '+=' + deltaZ,
      }, {
        easing: easing,
        delay: delay,
        duration: dur,
        display: display,
      })
      // Velocity($el,{
      //   opacity: opacity,
      //     translateX: '-=' + deltaX,
      //     translateY: '-=' + deltaY,
      //     translateZ: '-=' + deltaZ,
      // },{
      //    duration: 0,
      //     display: display,
      // //   easing: easing,

      // })


    },

    fadeInDirInactivePLayers($el, dur, delay, deltaX = 0, deltaY = 0, deltaZ = 0, easing = null, opacity = 0) {
      var display = ""
      if (!opacity)
        display = 'none'
      else
        display = 'block'
      Velocity($el, {
        opacity: opacity,
        translateX: '-=' + deltaX,
        translateY: '-=' + deltaY,
        translateZ: '-=' + deltaZ,
      }, {
        duration: 0,
        display: display,
        easing: easing,

      })
    },
    attachAll() {
      for (var i = 0; i < this.$players.length; i++) {
        this.$players[i].addEventListener('click', (e) => {
          console.log('heuu', e.currentTarget);
          console.log('classes before', e.currentTarget.classList);
          e.currentTarget.classList.add("active");
          console.log('classes after', e.currentTarget.classList);
          this.focusPlayer(e.currentTarget);
          return setTimeout(() => {
            return this.attachClose();
          }, 1)

          //  e.currentTarget.addClass('active');
          // this.$players[i].addClass('active');
          // this.focusPlayer(this.$players[i]);
          // return setTimeout((function() {
          //     return events.attachClose();
          // }), 1);
        });
      }

      // return this.$players.on('click', function(e) {
      //   console.log('on click player',e);
      //             // var $el;
      //             // e.preventDefault();
      //             // $el = $(this);
      //             // if ($('.active').length) {
      //             //     return false;
      //             // }
      //             // $el.addClass('active');
      //             // scenes.focusPlayer($el);
      //             // return setTimeout((function() {
      //             //     return events.attachClose();
      //             // }), 1);
      //         });
    },
    unfocusPlayer() {
      var $el = document.getElementsByClassName('active')[0];
      console.log('unfocus', $el);
      var allPlayers = document.getElementsByClassName('js-player')
      console.log('all players', allPlayers[0].classList);
      allPlayers.forEach((player) => {
        if (!player.classList.contains('active')) {
          console.log('not active', player.dataset);
          // var $focus;
          // $focus = player ;
          // return this.fadeOutDir($unfocus, 300, 300, player.dataset.x,player.dataset.y, 0, null, 0.2);
          // this.fadeInDir(player, 300, 300, 0, 0, 0, null, 0.2);
          // this.fadeInDir(player, 300, 300, 0, 0, 0, null, 0.2);

          // return this.fadeOutDir($unfocus, 300, 0, player.dataset.x,player.dataset.y, 0, null, 0.2);



          // const $playerLabel = player.getElementsByClassName('player__label')[0]
          // const $image = 
          // const $image = player.getElementsByClassName('player__img')[0]
          // console.log('player not active image',$image);
          // 
          // this.fadeInDir($playerLabel, 200, 600);
          // var data = player.dataset;
          this.fadeInDir(player, 300, 300, 0, 0, 0, null, 0.2);



          //  this.fadeOutDir(player, 300, 0, player.dataset.x,player.dataset.y, 0, null, 0.2);

          this.fadeInDirInactivePLayers(player, 300, 0, player.dataset.x, player.dataset.y, 0, null, 1)



          //     const $image = player.getElementsByClassName('player__img')[0]
          // 

          //   Velocity($image,{
          //    translateX:   data.x,
          //   translateY:   data.y,
          //   translateZ:   data.z,
          // },300)

        }
      })
      $el.classList.remove("active")
      Velocity(this.$world, {
        translateX: this.pos.world.baseX,
        translateY: this.pos.world.baseY,
        translateZ: this.pos.world.baseZ + 1000
      }, 600);
      Velocity(this.$terrain, {
        opacity: 1,
        // translateX: this.pos.world.baseX,
        // translateY: this.pos.world.baseY,
        // translateZ: this.pos.world.baseZ
      }, 600)
      return this.hidePlayerCard($el, 600, 600);
    },
    // 
    hidePlayerCard($el, dur, delay) {

      const $card = $el.getElementsByClassName('player__card')[0]
      const $image = $el.getElementsByClassName('player__img')[0]
      const $playerLabel = $el.getElementsByClassName('player__label')[0]
      Velocity($image, {
        translateY: 0
      }, 300)
      this.fadeInDir($playerLabel, 200, delay);
      return this.fadeOutDir($card, 300, 0, 0, -100);
    },
    attachClose() {
      this.$stage.addEventListener('click', (e) => {
        console.log('attach close', e);
        e.preventDefault();
        return this.unfocusPlayer();
      })
    }


  },


  mounted() {
    this.$stage = document.getElementsByClassName("js-stage")[0];
    // setTimeout(() => {
    // console.log('this stage',this.$stage);

    // },1000)

    this.$world = document.getElementsByClassName('js-world')[0]
    this.$switchBtn = document.getElementsByClassName('js-switch')[0]
    this.$loadBtn = document.getElementsByClassName('js-load')[0]
    this.$heading = document.getElementsByClassName('js-heading')[0]
    this.$switcher = document.getElementsByClassName('js-switcher')[0]
    this.$closeBtn = document.getElementsByClassName('js-close')[0]
    this.$subHeading = document.getElementsByClassName('js-subheading')[0]
    this.$terrain = document.getElementsByClassName('js-terrain')[0]
    this.$team = document.getElementsByClassName('js-team')[0]
    this.$teamListHome = document.getElementsByClassName('js-team-home')[0]
    this.$loading = document.getElementsByClassName('js-loading')[0]
    // console.log('$teamListHome',document.getElementsByClassName('js-loading'));
    this.addPlayers('home')
    // this.addPlayers('away')
    // this.preLoad()
    this.arrangePlayers()
    this.attachAll()
    // scenes.startLoading()
  }
}
